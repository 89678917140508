import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const ProjectsPage = () => {
    return (
      <Layout pageMeta={{ title: "Projects" }}>  

        <h1 class="text-2xl">Projects</h1>
        <br />
        I am currently working on the website for <Link to="https://handmadevintagemarket.com"><strong>Handmade Vintage Market</strong></Link>.

      </Layout>
    )
  }

export default ProjectsPage